body {
  margin: 0;
  font-family: Play, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #100B15;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.audiowide-regular {
  font-family: "Audiowide", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.play-regular {
  font-family: "Play", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.play-bold {
  font-family: "Play", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.rotated {
  transform: rotate(180deg);
}