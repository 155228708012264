.logo-selector {
    border: 1px solid transparent;
    border-radius: 20px;
}

.logo-selector:hover {
    border: 1px solid #0093CE;
}

.blue-text {
    color: #0093CE;
    font-weight: bold;
}

.pink-text {
    color: #A2318A;
    font-weight: bold;
}

.pink-border {
    border: 1px solid #A2318A;
    border-radius: 20px;
}

.blue-border {
    border: 1px solid #0093CE;
    border-radius: 20px;
}